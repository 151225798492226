.home {
  background-image: var(--first-gradient);
  padding-bottom: 75px;
}

.home__wrapper {
  min-height: 100vh;
  display: grid;
  align-items: center;
}

.home__container {
  width: 100%;
  position: relative;
}

.home__subtitle,
.home__job {
  font-weight: 700;
}

.home__subtitle,
.home__job span {
  font-size: var(--small-font-size);
  color: var(--title-color);
}

.home__subtitle span,
.home__title span {
  color: var(--primary-color);
}

.home__job b {
  font-family: var(--second-font);
  font-size: var(--h2-font-size);
  margin-left: 10px;
}

.home__title {
  color: #fff;
  font-size: var(--biggest-font-size);
  text-shadow: 2px 2px hsl(0, 0%, 0%), -2px 2px hsl(0, 0%, 0%),
    2px -2px hsl(0, 0%, 0%), -2px -2px hsl(0, 0%, 0%),
    5px 5px 0 rgb(0 0 0 / 20%);
  line-height: 1.2;
  margin-top: 15px;
}

.home__text {
  font-size: var(--largest-font-size);
  margin-block: 40px 28px;
  max-width: 520px;
}

.home__socials {
  display: flex;
  column-gap: 20px;
  margin-bottom: 30px;
}

.home__social-link {
  color: var(--title-color);
  font-size: var(--h5-font-size);
  transition: all 0.7s var(--transition);
}

.home__social-link:hover {
  color: var(--primary-color);
}

.home__btns {
  display: flex;
  column-gap: 70px;
}

.hero__link::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -97%;
  transform: translateY(-50%);
  width: 40px;
  height: 2px;
  background-color: var(--border-color);
}

/* .home__img-wrapper {
  position: absolute;
  top: 0;
  transform: translateY(-6%);
  left: 750px;
  width: 100%;
  max-width: 650px;
} */

.home__img-wrapper {
  position: absolute;
  top: 50px;
  transform: translateY(-6%);
  left: 50%;
  width: 100%;
  max-width: 570px;
  transform: translate(25%, -6%);
}

@media (max-width: 1480px) {
  .home__img-wrapper {
    max-width: 525px;
    left: 50%;
    top: 50px;
    transform: translate(25%, -6%);
  }
}

@media (max-width: 1360px) {
  .home__img-wrapper {
    max-width: 350px;
    left: 50%;
    top: 350px;
    transform: translate(20%, -6%);
  }
}

@media (max-width: 1180px) {
  .home__img-wrapper {
    max-width: 350px;
    left: 50%;
    top: 350px;
    transform: translate(15%, -6%);
  }
  .home__profile {
    position: absolute;
    bottom: 7px;
    height: 320px;
    border-radius: 500px;
    object-fit: fill;
  }
}

@media (max-width: 950px) {
  .home__img-wrapper {
    display: none;
  }
}

.home__banner {
  background-color: var(--primary-color);
  padding-bottom: 100%;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.home__profile {
  position: absolute;
  bottom: -10px;
  height: 750px;
  border-radius: 500px;
  object-fit: cover;
}

.home__data {
  border: 2px solid var(--border-color);
  background: var(--container-color);
  box-shadow: var(--shadow);
  position: absolute;
  padding-inline: 20px;
  width: 250px;
  height: 82px;
  border-radius: 82px;
  display: flex;
  align-items: center;
  z-index: 1;
}
.home__data_two {
  border: 2px solid var(--border-color);
  background: var(--container-color);
  box-shadow: var(--shadow);
  position: absolute;
  padding-inline: 20px;
  width: 250px;
  height: 82px;
  border-radius: 82px;
  text-align: center;
  padding-top: 12px;
  z-index: 1;
}

.home__data-one {
  bottom: 20%;
  left: -4%;
}

.home__data-two {
  bottom: 12%;
  right: 6%;
}

.home__data .text-lg,
.home__data .text-sm,
.home__data_two .text-sm {
  width: 50%;
  color: var(--title-color);
  font-weight: 700;
}

.home__data .text-lg {
  text-align: center;
  font-size: 37px;
}

.home__data .text-sm {
  font-size: 13px;
  line-height: 1.5em;
}

.home__data .text-lg b,
.home__data .text-sm span,
.home__data_two .text-sm {
  color: var(--primary-color);
}

.home__data .text-lg b {
  position: relative;
  top: -4px;
}

.shape__1 {
  width: 226px;
  height: 226 px;
  top: -6%;
  right: 6%;
}

.shape__2,
.shape__3 {
  width: 141px;
  height: 141px;
}

.shape__2 {
  bottom: 8%;
  left: -16%;
}

.shape__3 {
  bottom: 0;
  right: -2%;
}
